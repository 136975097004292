export const UTM_PARAMS = {
    utm_source: 'utm_source',
    utm_medium: 'utm_medium',
    utm_campaign: 'utm_campaign',
    utm_content: 'utm_content',
    utm_term: 'utm_term',
};

export const SIGN_IN_PARAMS = {
    redirect_path: 'redirect_path',
    auth_provider: 'auth_provider',
    redirect_url: 'redirect_url',
};

export const WIDGET_PARAMS = {
    widget_host: 'widget_host',
    partner_id: 'partner_id',
    language: 'language',
};

export const GOOGLE_PARAMS = {
    gclid: 'gclid',
    msclkid: 'msclkid',
    search_keyword: 'keyword',
};

export const CHAT_PARAMS = {
    send: 'send',
    dl: 'dl',
    share: 'share',
};

const QUERY_PARAMS_MAP = {
    ...UTM_PARAMS,
    ...SIGN_IN_PARAMS,
    ...WIDGET_PARAMS,
    ...GOOGLE_PARAMS,
    ...CHAT_PARAMS,
};

export const getParams = (query: URLSearchParams, params: {}): Record<string, string> => {
    return (
        Object.keys(params)
            // @ts-ignore
            .filter((key) => query.get(params[key]) !== null)
            .reduce((result, key) => {
                // @ts-ignore
                result[key] = query.get(params[key]);
                return result;
            }, {})
    );
};

export const mergeQueryParams = (query: string, parentQuery: string): Record<string, string> => {
    const parentParams = new URLSearchParams(parentQuery);
    let queryParams = getParams(parentParams, QUERY_PARAMS_MAP);

    const chatLinkParams = new URLSearchParams(query);
    const dl = chatLinkParams.get('dl');
    if (dl !== null) {
        queryParams = { ...queryParams, dl };
    }

    const send = chatLinkParams.get('send');
    if (send !== null) {
        queryParams = { ...queryParams, send };
    }

    const deeplinkUtmParams = getParams(chatLinkParams, UTM_PARAMS);
    if (Object.keys(deeplinkUtmParams).length > 0) {
        Object.keys(UTM_PARAMS).forEach((key) => delete queryParams[key]);
        queryParams = { ...queryParams, ...deeplinkUtmParams };
    }

    return queryParams;
};

export const getQueryParams = (query: URLSearchParams) => {
    return getParams(query, QUERY_PARAMS_MAP);
};

import { useStore } from 'lib/store';
import { useRouter } from 'next/router';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import { Event, sendEvent } from '../lib/events';
import { useUserState } from './chat/hooks/UseGlobalState';
import { useMenu } from './chat/hooks/UseMenu';
import HeaderTitle from './HeaderTitle';
import { Burger, Exit, File, Help, Info, Logout, Register, Shield } from './icons';

export interface MenuIconProps {
    isActive?: boolean;
    paddingTop?: number;
}

export interface MenuItemProps {
    color?: string;
    background?: string;
    highlightColor?: string;
}

export const Header = styled.div((props) => ({
    width: '100%',
    height: 60,
    background: props.theme.colors.background,
    border: `1px solid ${props.theme.colors.grey}`,
}));

export const HeaderElementsWrapper = styled.div({
    maxWidth: 640,
    margin: '0 auto',
    height: 60,
    display: 'flex',
    alignItems: 'center',
});

export const MenuIcon = styled.button.attrs((props) => ({
    role: 'button',
    'aria-label': props['aria-label'],
}))<MenuIconProps>((props) => ({
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: props.paddingTop || 7,
    paddingBottom: 5,
    borderRadius: 4,
    cursor: 'pointer',
    userSelect: 'none',
    webkitTapHighlightColor: 'transparent',
    background: props.isActive ? '#f0f0f0' : 'none',
    '&:hover': {
        background: !isMobile || props.isActive ? '#f0f0f0' : 'none',
    },
    '&:focus': {
        outline: 'none',
    },
}));

export const BurgerIcon = styled(Burger)<MenuIconProps>((props) => ({
    height: 24,
    width: 22,
    background: props.isActive ? '#f0f0f0' : 'none',
    '&:hover': {
        background: !isMobile || props.isActive ? '#f0f0f0' : 'none',
    },
}));

export const ExitIcon = styled(Exit)<MenuIconProps>((props) => ({
    height: 24,
    width: 24,
    background: props.isActive ? '#f0f0f0' : 'none',
    '&:hover': {
        background: !isMobile || props.isActive ? '#f0f0f0' : 'none',
    },
}));

export const MenuItemsWrapper = styled.div<{ height?: number }>((props) => ({
    top: 44,
    marginLeft: -287,
    height: props.height || 270,
    width: 300,
    zIndex: 1,
    position: 'relative',
    webkitBoxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    '@media (max-width: 600px)': {
        top: 60,
        width: '100%',
        position: 'absolute',
        left: 0,
        marginLeft: 0,
    },
    '&:before': {
        content: '',
        height: 10,
        width: '100%',
        position: 'absolute',
        top: -10,
        background: '#fff',
        zIndex: 1,
        borderBottom: '1px solid #e9eef2',
    },
}));

export const MenuItem = styled.a<MenuItemProps>((props) => ({
    width: 300,
    '@media (max-width: 600px)': {
        width: '100%',
    },
    backgroundColor: props.background || props.theme.colors.background,
    color: props.color || props.theme.colors.text,
    float: 'left',
    textAlign: 'left',
    padding: 14,
    paddingLeft: 21,
    textDecoration: 'none',
    fontSize: 16,
    fontFamily: props.theme.fonts.default,
    lineHeight: '26px',
    display: 'flex',
    '&:hover': {
        backgroundColor: props.highlightColor
            ? `${props.highlightColor} !important`
            : '#f0f0f0 !important',
    },
    cursor: 'pointer',
    height: MENU_ITEM_HEIGHT,
}));

export const MenuItemIcon = styled.div({
    width: 24,
    height: 24,
    marginRight: 14,
    cursor: 'pointer',
});

export const MenuIconWrapper = styled.div<{ marginLeft?: number }>((props) => ({
    height: 32,
    width: 42,
    alignSelf: 'center',
    display: 'flex',
    marginLeft: props.marginLeft,
}));

export const HeaderRightWrapper = styled.div({
    marginLeft: 'auto',
    marginRight: 10,
    height: '100%',
    display: 'flex',
});

export interface IMenuItem {
    href?: string;
    text: string;
    color?: string;
    background?: string;
    highlightColor?: string;
    action?: () => void;
    target: string;
    icon: () => JSX.Element;
}

const MENU_ITEM_HEIGHT = 54;

const DefaultHeader = () => {
    const theme = useTheme();
    const { t } = useTranslation('menu');
    const { isOpen, menuRef, setIsOpen } = useMenu();
    let menuItems: Array<IMenuItem>;
    const user = useUserState((state) => state.user);
    const logout = useUserState((state) => state.logout);
    const router = useRouter();

    const DEFAULT_MENU_ITEMS: Array<IMenuItem> = [
        {
            href: 'https://www.eddytravels.com/about',
            text: t('about'),
            target: '_blank',
            icon: Info,
        },
        {
            href: 'https://www.eddytravels.com/contact',
            text: t('support'),
            target: '_blank',
            icon: Help,
        },
        {
            href: 'https://www.eddytravels.com/terms-of-service',
            text: t('terms-and-conditions'),
            target: '_blank',
            icon: File,
        },
        {
            href: 'https://www.eddytravels.com/privacy-policy',
            text: t('privacy-policy'),
            target: '_blank',
            icon: Shield,
        },
    ];

    if (!user || user.guest) {
        menuItems = [
            {
                href: 'login',
                text: t('register'),
                color: '#FFFFFF',
                background: theme.colors.primary,
                highlightColor: theme.colors.primary,
                target: '',
                icon: Register,
            },
        ];
        menuItems = menuItems.concat(DEFAULT_MENU_ITEMS);
    } else {
        menuItems = DEFAULT_MENU_ITEMS;
        menuItems = menuItems.concat([
            {
                action: async () => {
                    await logout();
                    await router.push('/login');
                },
                text: t('logout'),
                target: '',
                icon: Logout,
            },
        ]);
    }
    const isWidget = useStore((store) => store.isWidget);
    const isEmbedded = useStore((store) => store.isEmbedded);
    const hideCloseButton = useStore((store) => store.config.hideCloseButton);
    return (
        <Header>
            <HeaderElementsWrapper>
                {isWidget ? (
                    <MenuIconWrapper marginLeft={12}>
                        {!isEmbedded && hideCloseButton !== true ? (
                            <MenuIcon
                                aria-label="Close"
                                onClick={() => {
                                    sendEvent({ type: Event.WIDGET_CLOSED });
                                    window.parent.postMessage(
                                        { type: '__EDDY_TRAVELS_CHAT_CLOSE' },
                                        '*'
                                    );
                                }}
                            >
                                <ExitIcon color={theme.colors.icon} />
                            </MenuIcon>
                        ) : null}
                    </MenuIconWrapper>
                ) : null}
                <HeaderTitle />
                <HeaderRightWrapper>
                    <MenuIconWrapper ref={menuRef}>
                        {!isWidget ? (
                            <MenuIcon
                                onClick={() => setIsOpen((isOpen) => !isOpen)}
                                isActive={isOpen}
                            >
                                <BurgerIcon isActive={isOpen} />
                            </MenuIcon>
                        ) : null}
                        {isOpen && (
                            <MenuItemsWrapper height={menuItems.length * MENU_ITEM_HEIGHT}>
                                {menuItems.map((item: IMenuItem) => (
                                    <MenuItem
                                        key={item.href}
                                        href={item.href}
                                        target={item.target}
                                        onClick={item.action}
                                        background={item.background}
                                        color={item.color}
                                        highlightColor={item.highlightColor}
                                    >
                                        <MenuItemIcon>{item.icon()}</MenuItemIcon>
                                        {item.text}
                                    </MenuItem>
                                ))}
                            </MenuItemsWrapper>
                        )}
                    </MenuIconWrapper>
                </HeaderRightWrapper>
            </HeaderElementsWrapper>
        </Header>
    );
};

export default DefaultHeader;

export const ArrowLeft = ({ color = '#000000' }: { color?: string }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path
            d="M18.4775 9.99988L3.51791 9.99988"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.35156 15.8333L3.51823 9.99992L9.35156 4.16658"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

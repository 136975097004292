import { ConversationItem } from '../components/chat/types';

const FALLBACK_TRACKING_ID = 'fallback_message';

export enum Event {
    CLICKED_ON_PREVIEW = 'CLICKED_ON_PREVIEW',
    SEND_BUTTON_CLICKED = 'SEND_BUTTON_CLICKED',
    CLICKED_QUICK_REPLY = 'CLICKED_QUICK_REPLY',
    CLICKED_CARD = 'CLICKED_CARD',
    LIKED_OFFER = 'LIKED_OFFER',
    UNLIKED_OFFER = 'UNLIKED_OFFER',
    WIDGET_CLOSED = 'WIDGET_CLOSED',
    RECEIVED_FLIGHT_OFFERS = 'RECEIVED_FLIGHT_RESULTS',
    CLICKED_ON_FLIGHT_OFFER = 'CLICKED_ON_FLIGHT_OFFER',
    RECEIVED_ACCOMMODATION_OFFERS = 'RECEIVED_ACCOMMODATION_OFFERS',
    CLICKED_ON_ACCOMMODATION_OFFER = 'CLICKED_ON_ACCOMMODATION_OFFER',
    RECEIVED_ACTIVITY_OFFERS = 'RECEIVED_ACTIVITY_OFFERS',
    CLICKED_ON_ACTIVITY_OFFER = 'CLICKED_ON_ACTIVITY_OFFER',
    RECEIVED_CAR_RENTAL_OFFERS = 'RECEIVED_CAR_RENTAL_OFFERS',
    CLICKED_ON_CAR_RENTAL_OFFER = 'CLICKED_ON_CAR_RENTAL_OFFER',
    COULD_NOT_UNDERSTAND = 'COULD_NOT_UNDERSTAND',
    CLICKED_ON_MESSAGE_LINK = 'CLICKED_ON_MESSAGE_LINK',
}

export interface ChatEvent {
    type: Event;
}

export interface OpenedPreviewEvent extends ChatEvent {
    type: Event.CLICKED_ON_PREVIEW;
    button_title?: string;
}

export interface ClickedQuickReplyEvent extends ChatEvent {
    type: Event.CLICKED_QUICK_REPLY;
    tracking_id?: string;
    label: string;
}

export interface ClickedCardEvent extends ChatEvent {
    type: Event.CLICKED_CARD;
    tracking_id?: string;
    tracking_label?: string;
    card_title: string;
    button_title: string;
}

export interface LikedOfferEvent extends ChatEvent {
    type: Event.LIKED_OFFER | Event.UNLIKED_OFFER;
    like_type: string;
}

export interface ReceivedAccommodationOffersEvent extends ChatEvent {
    type: Event.RECEIVED_ACCOMMODATION_OFFERS;
    check_in_date: string;
    check_out_date: string;
    location: string;
}

export interface ClickedOnAccommodationOfferEvent extends ChatEvent {
    type: Event.CLICKED_ON_ACCOMMODATION_OFFER;
    accommodation_type: string;
    provider: string;
    name: string;
    stars: number;
    currency: string;
    price_per_night: number;
    total_price: number;
    rooms: number;
    check_in_date?: string;
    check_out_date?: string;
    location?: string;
    external_id: string;
}

export interface ReceivedFlightOffersEvent extends ChatEvent {
    type: Event.RECEIVED_FLIGHT_OFFERS;
    adult_passengers: number;
    departure_location: string;
    departure_date: string;
    destination_location: string;
    return_date: string;
}

export interface ClickedOnFlightOfferEvent extends ChatEvent {
    type: Event.CLICKED_ON_FLIGHT_OFFER;
    provider: string;
    departure_location?: string;
    departure_date?: string;
    destination_location?: string;
    return_date?: string;
    outbound_leg: {
        airlines: string[];
        destination_airport: string;
        origin_airport: string;
    };
    inbound_leg?: {
        airlines: string[];
        destination_airport: string;
        origin_airport: string;
    };
    children_passengers: number;
    infant_passengers: number;
    adult_passengers: number;
    cabin_class: string;
    currency: string;
    price: number;
}

export interface ReceivedActivityOffersEvent extends ChatEvent {
    type: Event.RECEIVED_ACTIVITY_OFFERS;
    location: string;
}

export interface ClickedOnActivityOfferEvent extends ChatEvent {
    type: Event.CLICKED_ON_ACTIVITY_OFFER;
    location?: string;
    title: string;
    subtitle: string;
    rating: number;
    price: number;
    currency: string;
    duration: string;
}

export interface ReceivedCarRentalOffersEvent extends ChatEvent {
    type: Event.RECEIVED_CAR_RENTAL_OFFERS;
    pick_up_location?: string;
    pick_up_time?: string;
    drop_off_location?: string;
    drop_off_time?: string;
}

export interface ClickedOnCarRentalOfferEvent extends ChatEvent {
    type: Event.CLICKED_ON_CAR_RENTAL_OFFER;
    pick_up_location?: string;
    pick_up_time?: string;
    drop_off_location?: string;
    drop_off_time?: string;
    total_price: number;
    currency: string;
    vehicle: string;
}

export interface CouldNotUnderstandEvent extends ChatEvent {
    type: Event.COULD_NOT_UNDERSTAND;
    reference_message?: string;
}

export interface ClickedOnMessageLink extends ChatEvent {
    type: Event.CLICKED_ON_MESSAGE_LINK;
    url?: string;
}

export const sendEvent = (event: ChatEvent) => {
    window.parent.postMessage({ type: '__EDDY_TRAVELS_CHAT_EVENT', event }, '*');
};

export const handleChatMessageEvents = (
    message: ConversationItem,
    lastMessage?: ConversationItem
) => {
    if (message?.bot?.flight_search_result?.Query) {
        const result = message.bot.flight_search_result;
        const returnDate = result.Query.ReturnDate ? result.Query.ReturnDate.End : '';
        const chatEvent: ReceivedFlightOffersEvent = {
            type: Event.RECEIVED_FLIGHT_OFFERS,
            departure_date: result.Query.DepartureDate?.Start,
            return_date: returnDate,
            departure_location: result.Query.OriginNameEn,
            destination_location: result.Query.DestinationNameEn,
            adult_passengers: result.Query.AdultPassengers,
        };
        sendEvent(chatEvent);
    } else if (message?.bot?.accommodation_search_result?.Query) {
        const result = message.bot.accommodation_search_result;
        const chatEvent: ReceivedAccommodationOffersEvent = {
            type: Event.RECEIVED_ACCOMMODATION_OFFERS,
            check_in_date: result.Query.CheckInDate,
            check_out_date: result.Query.CheckOutDate,
            location: result.Query.Location.NameEn,
        };
        sendEvent(chatEvent);
    } else if (message?.bot?.car_hire_search_result?.Query) {
        const result = message.bot.car_hire_search_result;
        const chatEvent: ReceivedCarRentalOffersEvent = {
            type: Event.RECEIVED_CAR_RENTAL_OFFERS,
            pick_up_location: result.Query?.PickUpLocation?.NameEn,
            drop_off_location: result.Query?.DropOffLocation?.NameEn,
            pick_up_time: result.Query?.PickUpTime,
            drop_off_time: result.Query?.DropOffTime,
        };
        sendEvent(chatEvent);
    } else if (message?.bot?.activity_search_result?.Query) {
        const result = message.bot.activity_search_result;
        const chatEvent: ReceivedActivityOffersEvent = {
            type: Event.RECEIVED_ACTIVITY_OFFERS,
            location: result.Query.Location.NameEn,
        };
        sendEvent(chatEvent);
    } else if (message?.bot?.tracking_id === FALLBACK_TRACKING_ID) {
        const chatEvent: CouldNotUnderstandEvent = {
            type: Event.COULD_NOT_UNDERSTAND,
            reference_message: lastMessage?.user?.text,
        };
        sendEvent(chatEvent);
    }
};

import { useStore } from 'lib/store';
import { useRouter } from 'next/router';
import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

import { useUserState } from './chat/hooks/UseGlobalState';
import { TextDirection } from './chat/types';
import { EddyTravelsBanner } from './EddyTravelsBanner';
import { Info } from './icons';

const LogoWrapper = styled.div<{ marginTop?: number }>((props) => ({
    height: 38,
    width: 'calc(100% - 60px)',
    marginLeft: 10,
    textAlign: 'center',
    marginTop: props.marginTop,
}));

const Settings = styled.div((props) => ({
    height: '14px',
    color: props.theme.colors.darkgrey,
    fontFamily: props.theme.fonts.default,
    fontSize: '12px',
    lineHeight: '14px',
    cursor: 'pointer',
}));

export const HeaderTitleWrapper = styled.div((props) => ({
    fontFamily: props.theme.fonts.default,
    fontStyle: 'normal',
    textAlign: 'center',
    width: 'calc(100% - 60px)',
    outline: 'none',
    whiteSpace: 'nowrap',
}));

export const ChannelTitle = styled.div<{ direction?: TextDirection }>((props) => ({
    height: '20px',
    color: props.theme.colors.text,
    fontFamily: props.theme.fonts.default,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
    direction: props.direction || 'ltr',
}));

const HeaderTitle = (props: { toggleSettings?: any }) => {
    const userGroups = useUserState((state) => state.groups);
    const router = useRouter();
    const group = userGroups.find((group) => group.id === router.query['id']);
    const groupName = group?.name;
    const botName = useStore((store) => store.config.botName);
    const headerIconUrl = useStore((store) => store.config.icons?.headerIconUrl);
    const hidePoweredBy = useStore((store) => store.config.hidePoweredBy);

    const textDirection = useStore((store) => store.textDirection);

    if (group) {
        return (
            <HeaderTitleWrapper onClick={props.toggleSettings}>
                <ChannelTitle>{groupName}</ChannelTitle>
                <Settings>
                    <Info
                        css={{
                            width: '10px',
                            height: '10px',
                            verticalAlign: 'middle',
                            color: '#7C8B99',
                            path: {
                                stroke: '#7C8B99',
                            },
                            marginRight: '2px',
                        }}
                    />
                    Settings
                </Settings>
            </HeaderTitleWrapper>
        );
    } else if (headerIconUrl) {
        if (hidePoweredBy) {
            return (
                <LogoWrapper>
                    <img src={headerIconUrl} height={38} />
                </LogoWrapper>
            );
        }
        return (
            <LogoWrapper marginTop={-10}>
                <img src={headerIconUrl} height={30} />
                <EddyTravelsBanner fontSize={isMobile ? 10 : 12} margin="-2px -8px 0 -8px" />
            </LogoWrapper>
        );
    }
    return (
        <HeaderTitleWrapper>
            <ChannelTitle direction={textDirection}>{botName}</ChannelTitle>
            {!hidePoweredBy ? (
                <EddyTravelsBanner fontSize={isMobile ? 10 : 12} margin="auto" />
            ) : null}
        </HeaderTitleWrapper>
    );
};

export default HeaderTitle;

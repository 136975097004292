import { useCallback, useEffect, useRef, useState } from 'react';

export const useMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const closeMenu = useCallback(() => setIsOpen(false), []);
    const menuRef = useRef<HTMLDivElement>(null);
    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as HTMLDivElement)) {
                closeMenu();
            }
        },
        [menuRef.current]
    );
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);
    return { isOpen, menuRef, setIsOpen };
};

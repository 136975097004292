import { useStore } from 'lib/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const BannerLink = styled.a<{ fontSize: number }>((props) => ({
    fontFamily: props.theme.fonts.default,
    fontSize: props.fontSize,
    color: props.theme.colors.primary,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

const BannerText = styled.div<{ fontSize: number; margin: string }>((props) => ({
    fontFamily: props.theme.fonts.default,
    fontSize: props.fontSize,
    color: props.theme.colors.darkgrey,
    textAlign: 'center',
    margin: props.margin,
}));

export const EddyTravelsBanner = ({
    fontSize = 14,
    margin,
}: {
    fontSize?: number;
    margin: string;
}) => {
    const privacyPolicyUrl = useStore((store) => store.config.privacyPolicyUrl);
    const { t } = useTranslation('chat');
    return (
        <BannerText fontSize={fontSize} margin={margin} dir="auto">
            {t('by-eddy-ai')} |{' '}
            <BannerLink fontSize={fontSize} target="_blank" href={privacyPolicyUrl}>
                {t('privacy')}
            </BannerLink>
        </BannerText>
    );
};

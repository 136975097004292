import { useUserState } from 'components/chat/hooks/UseGlobalState';
import { Location, LocationType } from 'components/chat/types';
import { NextRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Linking, Platform } from 'react-native';

import { WIDGET_PARAMS, getParams, getQueryParams, mergeQueryParams } from './queryParams';
import { useStore } from './store';

export interface WindowSize {
    width: number;
    height: number;
}

export const useWindowSize = () => {
    const isClient = typeof window === 'object';

    const getSize = () => {
        return {
            width: isClient ? window.innerWidth : 0,
            height: isClient ? window.innerHeight : 0,
        };
    };

    const [windowSize, setWindowSize] = useState<WindowSize>(getSize);

    useEffect(() => {
        if (!isClient) {
            return () => {};
        }

        const handleResize = () => {
            setWindowSize(getSize());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export const useWidgetClickHandler = (router: NextRouter) => {
    useEffect(() => {
        const handleMessage = (message: MessageEvent) => {
            if (message.data.type === '__EDDY_TRAVELS_CHAT_DEEPLINK') {
                const trackingParams = mergeQueryParams(
                    message.data.query,
                    message.data.parentQuery
                );
                // @ts-ignore
                const searchParams = new URLSearchParams(window.location.search);
                const widgetParams = getParams(searchParams, WIDGET_PARAMS);
                router.replace(`/?${new URLSearchParams({ ...trackingParams, ...widgetParams })}`);
            }
        };
        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [router.query]);
};

const fallbackCopyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
    } finally {
        document.body.removeChild(textArea);
    }
};

export const copyToClipboard = async (text: string) => {
    if (!navigator.clipboard) {
        return fallbackCopyToClipboard(text);
    }
    return navigator.clipboard.writeText(text);
};

export const openLink = (href: string, target: '_blank' | '_self') => {
    if (Platform.OS === 'web') {
        const a = target || '_blank';
        return window.open(href, target || '_blank');
    }
    return Linking.canOpenURL(href).then(() => Linking.openURL(href));
};

export const shareQueryParam = 'share=true';

export const useLogin = (additionalQueryParams?: string) => {
    const partnerId = useStore((store) => store.partnerId);
    const login = useUserState((state) => state.login);
    return () => {
        // @ts-ignore
        const searchParams = new URLSearchParams(window.location.search);
        const queryParams = getQueryParams(searchParams);
        if (!queryParams['partner_id'] && partnerId) {
            queryParams['partner_id'] = partnerId;
        }
        let parsedQueryParams = new URLSearchParams(queryParams).toString();
        if (additionalQueryParams) {
            parsedQueryParams += `${additionalQueryParams}`;
        }
        return login(parsedQueryParams);
    };
};

export const getCountry = (location: Location): Location => {
    if (location.Type === LocationType.Country) {
        return location;
    }
    if (location.Country) {
        return location.Country;
    }

    if (location?.City?.Country) {
        return location.City.Country;
    }

    return location;
};
